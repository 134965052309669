<template>
  <div>
    <a-modal :visible="visible" title="自产" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div>
        <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="goods" label="物料">
            <a-input v-model="goodsItem.name" disabled />
          </a-form-model-item>
          <a-form-model-item prop="total_quantity" label="计划数量">
            <a-input-number v-model="dataForm.total_quantity" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="planned_start_time" label="计划开始时间">
            <a-date-picker
              v-model="dataForm.planned_start_time"
              placeholder="请选择时间"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item prop="planned_end_time" label="计划完成时间">
            <a-date-picker
              v-model="dataForm.planned_end_time"
              placeholder="请选择时间"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item prop="batch_number" label="批号">
            <a-input v-model="dataForm.batch_number" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="dataForm.remark" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderInternal } from "@/api/production";

export default {
  props: ["visible", "productionOrderItem", "bomItem", "goodsItem"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        total_quantity: [{ required: true, message: "请输入计划数量", trigger: "change" }],
        planned_start_time: [{ required: true, message: "请选择时间", trigger: "change" }],
        planned_end_time: [{ required: true, message: "请选择时间", trigger: "change" }],
      },
      loading: false,
      dataForm: {},
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          productionOrderInternal({ id: this.productionOrderItem.id, ...this.dataForm, goods: this.goodsItem.id })
            .then((data) => {
              this.$message.success("新增成功");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.dataForm = {
          total_quantity: this.bomItem.deficit_quantity,
          planned_start_time: undefined,
          planned_end_time: undefined,
          batch_number: "",
          remark: "",
        };
      }
    },
  },
};
</script>

<style scoped></style>
